<template>
  <div class="outcome">
    <Header/>

    <section class="outcome__section">
      <h1 class="outcome__title">
        Så har vi räknat
      </h1>

      <StackedColumns :data="this.graphData" :rawData="this.rawData"/>

      <div class="text-box">
        <div class="text-box__text">
          <h2>Jämförelse nuläge</h2>
          <p>
            Ovanstående illustration av månatlig pensionsutbetalning i de olika pensionsplanerna KAP-KL och AKAP-KR är en ögonblicksbild baserad på nuvarande förutsättningar.
            <br/>
            <br/>
            Kapital vid pensionsålder illustrerar beräknat värde av pension vid 65 års ålder. Totalt kapital illustrerar värdet av genomsnittligt totalt utbetald pension efter 23 år i dessa pensionsplanerna   (Beräkningsunderlag för livsvarigt pensionsuttag). Dessa skiljer sig åt i de olika pensionsplanerna då förutsättningar för kapitaltillväxt på pensionskapitalet ser olika ut.
            <br/>
            <br/>
            Illustrationen visar endast de pensionsförmåner som tjänas in från idag fram till 65 års ålder med ett pensionsuttag på 23 år från 65 års ålder.
            <br/>
            <br/>
            Beräkningarna bygger på nuvarande angiven lön för att göra det enklare att relatera till din framtida pension. Om vi i stället hade räknat upp prognosen med inflation och löneförändringar så hade den blivit högre än vad den visar idag. I stället har vi räknat med en årlig avkastning på 1,9%. 1,9% är den prognosstandard som pensionsbolag och myndigheter kommit överens om för kalkyler som denna för att sätta den framtida prognosen i relation till nuvarande lön.
            <br/>
            <br/>
            Prognosen är endast en beräkning av framtida värde i pension och tar ej hänsyn till privata förutsättningar vad gäller tidigare intjänade pensionsförmåner, efterlevandeskydd, möjlig uttagsålder, utbetalningstid av pensionskapital, slutbetalningsfunktioner, lönemaximering, förvaltningsmöjligheter samt övrigt avtalade skillnader.
            <br/>
            <br/>
            För en individuell helhetstäckande konsekvensbeskrivning krävs mer djupgående rådgivning efter individuell unik situation.
            <br/>
            <br/>
            Denna prognos utgör ingen garanti och kommer sannolikt att avvika mot prognosen. Illustrationen syftar endast till att ge en ögonblicksbild av framtida intjäning av pension enligt givna förutsättningar
          </p>
        </div>
      </div>
    </section>

    <button
      class="btn btn--full-width btn--align-bottom"
      @click="back()"
      v-if="!this.roleAnalysis"
    >
      Tillbaka
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StackedColumns from '../StackedColumns.vue'
import Header from '../Header.vue'

export default {
  name: 'CurrentAnalysisOutcome',
  props: [ 'data', 'roleAnalysis', 'back' ],
  components: { StackedColumns, Header },
  computed: {
    ...mapGetters(['auth', 'user']),
    graphData() {
      return [{
          "type": "Stanna kvar\n(kr/mån)",
          "kap-kl-pr": Math.round(this.user.kapkl.kapkl.premieWorthAfterPension),
          "kap-kl-fr": Math.round(this.user.kapkl.kapkl.benefitWorth),
        },
        {
          "type": "Vid byte\n(kr/mån)",
          "akap-kr-pr": Math.round(this.user.kapkl.akapkr.totalAfterPension),
        }]
    },
    rawData() {
      return this.user.kapkl
    }
  },
}
</script>

<style lang="scss" scoped>
.outcome {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img:first-of-type {
      width: 100px;
    }
  }

  &__title {
    text-align: center;
  }

  @include breakpoints(medium) {
    &__section, .btn {
      width: 70%;
      margin: 0 auto;
    }
  }
}
</style>