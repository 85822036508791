<template>
  <main class="home">
    <Header/>

    <section>
      <h1 class="home__title">
        Välkommen {{user.firstName}}!
      </h1>

      <div class="text-box">
        <div class="text-box__text">
          <p>
            Du som har en månadslön som vid årsskiftet överstiger <strong>45 250 kr</strong> har möjlighet att välja om du vill behålla nuvarande pensionsplan eller byta till den nya.
            <br><br>
            Denna tjänst är skapad för att enkelt visa vad alternativen kan innebära för dig från idag och framåt.
          </p>
        </div>
      </div>
    </section>

    <div class="home__bottom">
      <router-link class="link" to="/timeline">Så här fungerar det</router-link>

      <button
        class="btn btn--align-bottom btn--full-width"
        @click="next"
      >
        Nästa
      </button>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '../components/Header.vue'

export default {
  name: 'Home',
  components: { Header },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    next() {
      this.$router.replace({ name: 'current-analysis' })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img:first-of-type {
      width: 100px;
    }
  }

  &__bottom {
    text-align: center;
    width: 100%;
  }

  @include breakpoints(medium) {
    width: 60%;
    margin: 0 auto;
    padding: 3rem;

    &__title {
      max-width: 70%;
      margin: 0 auto 1rem auto;
    }

    &__bottom, .text-box {
      max-width: 70%;
      margin: 0 auto;
    }
  }
}
</style>
