<template>
    <div class="header">
      <img
        class="header__logo"
        src="../../src/assets/images/pond_logo_green.png"
        alt=""
      >

      <router-link
        to=""
        @click.native="logout"
        aria-label="Log out of this service"
        class="header__logout"
        v-if="this.$route.fullPath != '/login' && this.$route.fullPath != '/gdpr'"
      >
        <img
          src="../../src/assets/icons/door-closed.svg"
          alt=""
        >
        <img
          src="../../src/assets/icons/door-open.svg"
          alt=""
        >
      </router-link>
    </div>
</template>

<script>
import { eraseCookie } from '../services/cookie';

export default {
    name: 'Header',
    methods: {
      logout() {
        eraseCookie('pod');
        location.reload();
      },
    }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 100px;
  }

  &__logout {
    filter: $to-main-color;

    img {
      height: 30px;

      &:last-of-type {
        display: none;
      }
    }

    &:hover {
      img {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: block;
          margin-top: -6px;
          transform: scale(1.09);
        }
      }
    }
  }
}
</style>