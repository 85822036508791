<template>
  <main class="login">
    <Header/>

    <div class="login__bottom">
      <div class="login__info">
        <h1>Inför val av framtida pensionsplan.</h1>

        <p>
          Ta reda på hur det påverkar din framtida pension.
        </p>
      </div>

      <div class="login__input">
        <img
          class="login__bankid-logo"
          src="../../src/assets/images/BankID_logo.png"
          alt=""
        />

      </div>

      <div v-show="auth.loading && auth.qrLink">
        <img :src="qrImg" />
      </div>
      <LoadingSpinner
        class="loading"
        v-show="auth.loading && !auth.qrLink"
      />

      <a
        class="btn"
        :href="auth.qrLink"
        v-if="auth.loading"
      >
        Logga in på samma enhet
      </a>
      <button
        class="btn"
        @click="handleLogin"
        v-if="!auth.loading"
      >
        Logga in
      </button>

      <router-link
        class="link"
        to="/gdpr"
        aria-label="Läs mer om hur vi hanterar dina personuppgifter."
      >
        GDPR
      </router-link>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import QRious from 'qrious'
import { validatePersonalnumber } from '../services/validation'
import LoadingSpinner from '../components/LoadingSpinner'
import Header from '../components/Header.vue'

export default {
  name: 'Home',
  data() {
    return {
      ssn: '',
    }
  },
  components: { LoadingSpinner, Header },
  methods: {
    ...mapActions(['signIn', 'cancelSignIn']),
    handleLogin() {
      this.loading = true;
      this.signIn({
        'signInMethod': 0
      })
    }
  },
  computed: {
    ...mapGetters(['auth', 'user']),
    qrImg () {
        return new QRious({
            value: this.auth.qrLink
        }).toDataURL();
    }
  },
  watch: {
    auth (newValue, oldValue) {
      if (newValue.loggedIn) {
        location.reload()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img:first-of-type {
      width: 100px;
    }
  }

  .loading {
    height: 100px;
  }

  &__bankid-logo {
    height: 100px;
  }

  &__input {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    &-field {
      padding-left: 60px !important;
      margin-top: 10px;
    }
  }

  &__info {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      width: 100%;
    }
  }

  @include breakpoints(medium) {
    width: 60%;
    margin: 0 auto;
    padding: 3rem;
  }

  @include breakpoints(large) {
    &__bottom {
      max-width: 70%;
      height: 100%;
      justify-content: center;
    }
  }
}
</style>