import fetch from '../services/fetch';
import { createCookie, readCookie } from '../services/cookie';

const state = {
  auth: {
    loading: false,
    loggedIn: false,
    orderRef: null,
    msg: "",
    err: null,
    token: "",
    checkStatus: null,
    qrLink: '',
  }
};

const getters = {
  auth: (state) => state.auth
};

let interval;
let removedInterval;
const actions = {
  async signIn({ commit, dispatch }, { personNr, signInMethod }) {
    commit('resetAuth');
    let res_step_one;
    let url, data;
    let qrLink = '';

    //signInMethod 1 : Mobilt BankID , 2:BankId , 3:QR kod
    signInMethod == 1 ? data = { ssn: personNr } : data = { autostart: true };

    commit('setAuthLoading', true);

    try {
      res_step_one = await fetch.post('/login/bankid', data);
      if (!res_step_one.data.success) throw res_step_one.data.msg;
      qrLink = `bankid:///?autostarttoken=${res_step_one.data.AutoStartToken}&redirect=null`;
    } catch (err) {
      commit('setAuth', { loading: false, err: "connection lost" })
      return;
    }
    if (signInMethod == 2) {
      url = `https://app.bankid.com/?autostarttoken=${res_step_one.data.AutoStartToken}&redirect=null`;
      // If on mobile, open application.
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        document.location = url;
      } else {
        url = `bankid:///?autostarttoken=${res_step_one.data.AutoStartToken}&redirect=null`;
        document.location = url;
      }
    }
    commit('setAuth', { orderRef: res_step_one.data.orderRef, qrLink: qrLink });
    dispatch("checkStatus", { orderRef: res_step_one.data.orderRef, signInMethod });
  },
  async checkStatus({ commit, state, dispatch }, { orderRef, signInMethod }) {
    let counter = 0
    let res_step_two;
    let faild = false;
    let loggedIn = false;
    let ref = orderRef;
    let run = true;
    interval = setInterval(async function () {
      if (!run) return;
      run = false;
      if (!state.auth.checkStatus) { commit('setAuth', { checkStatus: interval }) }
      if (counter >= 30) { dispatch('cancelSignIn', ref); return; }

      // Sends request every 7nth second when not logged in or not failed.
      if (!loggedIn && !faild) {
        res_step_two = await fetch.post('/login/bankid', {
          status: ref
        });
      }
      if (interval == removedInterval) return;
      counter++
      if (res_step_two.data.progressStatus == 'USER_SIGN') {
        commit('setAuthMsg', "Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under.");
      }
      if (res_step_two.data.progressStatus == 'OUTSTANDING_TRANSACTION') {
        commit('setAuthMsg', 'Starta BankID-appen...');
        const res_step_one = await fetch.post('/login/bankid', { autostart: true });
        if (!res_step_one.data.success) throw res_step_one.data.msg;
        /*res_step_two = await fetch.post('/login/bankid', {
          status: ref
        });*/
        //if (res_step_two.data.progressStatus == 'OUTSTANDING_TRANSATION') {
            ref = res_step_one.data.orderRef
            const qrLink = `bankid:///?autostarttoken=${res_step_one.data.AutoStartToken}&redirect=null`;
            commit('setAuth', { orderRef: res_step_one.data.orderRef, qrLink: qrLink });
        //}
        run = true;
        return
      }
      else if (!res_step_two.data.success) {
        faild = true;
        window.clearInterval(interval);
        commit('setAuth', { loading: false, err: "Något gick fel. Var god försök igen" });
        return;
      }
      else if (res_step_two.data.progressStatus == "COMPLETE") {
        loggedIn = true;
        window.clearInterval(interval);
        createCookie('pod', res_step_two.data.token);
        commit('setAuth', {
          loading: false,
          loggedIn: true,
          orderRef: null,
          msg: "",
          err: "",
          token: res_step_two.data.token
        })
        dispatch('setUser', res_step_two.data.user);
        return;
      }
      console.log('SET AUTH')
      //const qrLink = `bankid:///?autostarttoken=${res_step_two.data.transactionId}&redirect=null`;
      //commit('setAuth', { qrLink: qrLink });
      run = true;
    }, 4000)
  },

  async cancelSignIn({ commit, state }, orderRef) {
    if (!orderRef) {
      commit('resetAuth');
      return;
    }
    try {
      window.clearInterval(interval);
      removedInterval = interval;
      commit('resetAuth');
    } catch (error) {
      console.log(error)
    } finally {
      fetch.post('/login/bankid', { cancel: { status: orderRef } });
    }
  },


  async check_loggedIn({ commit, dispatch }) {
    commit('setUserStatus', 'loading');
    let cookie = readCookie('pod');
    if (!cookie) {
      commit('setUserStatus', 'unauthorized');
      return false;
    }
    let user;
    try {
      user = await fetch.get('/user').then(res => res.data);
      dispatch('setUser', user.data);
    } catch (error) {
      commit('setUserStatus', 'unauthorized');
      throw error;
    }
    return true;
  },
};

const mutations = {
  setAuthLoading: (state, loadingStatus) => (state.auth.loading = loadingStatus),
  setAuth: (state, auth) => (state.auth = { ...state.auth, ...auth }),
  resetAuth: (state) => (state.auth = {
    loading: false,
    loggedIn: false,
    orderRef: null,
    msg: "",
    err: "",
    token: "",
    checkStatus: null,
    qrLink: ""
  }),
  setAuthMsg: (state, msg) => (state.auth.msg = msg)
};

export default {
  state,
  getters,
  actions,
  mutations
}