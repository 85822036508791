<template>
  <main class="current-analysis">
    <GatherInfo
      v-if="this.next == 0"
      @data="handleData"
    />

    <SimpleOutcome
      v-if="this.next == 1"
      :showGraph="showGraph"
      :data="this.data"
    />

    <Outcome
      v-if="this.next == 2"
      :back="back"
      :role-analysis="this.roleAnalysis"
      :data="this.data"
    />
  </main>
</template>

<script>
import GatherInfo from '../components/CurrentAnalysis/GatherInfo.vue'
import Outcome from '../components/CurrentAnalysis/Outcome.vue'
import SimpleOutcome from '../components/CurrentAnalysis/SimpleOutcome.vue'

export default {
  name: 'Home',
  props: [ 'roleAnalysisData' ],
  components: { SimpleOutcome, GatherInfo, Outcome },
  data() {
    return {
      next: 0,
      roleAnalysis: this.roleAnalysisData,
      data: []
    }
  },
  methods: {
    handleData() {
      // Logic for data.

      // Set data (Stacked Column).
      this.data = [{
          "type": "Vid pension",
          "kap-kl-pr": 10000,
          "kap-kl-fr": 7000,
          "akap-kr-pr": 30000,
          "akap-kr-fr": 0
        }, {
          "type": "Totalt",
          "kap-kl-pr": 10000,
          "kap-kl-fr": 4000,
          "akap-kr-pr": 30000,
          "akap-kr-fr": 0
        }]

      // Change to next view.
      this.next = 1
    },
    showGraph() {
      this.next = 2
    },
    back() {
      this.next = 1

      // Remove hash, if there's one.
      if (window.location.hash) {
        window.location.hash = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.current-analysis {
  height: 100%;
  padding: 1.875rem;
  background-color: $white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  &:has(.outcome) {
    height: auto;
  }

  @include breakpoints(medium) {
    width: 60%;
    margin: 0 auto;
    padding: 3rem;
  }
}
</style>
