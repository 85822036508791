import axios from 'axios';
import { readCookie } from './cookie';
const baseURL = 'https://48fec4fav1.execute-api.eu-north-1.amazonaws.com/Prod/api';

const fetch = axios.create({
  baseURL: baseURL
});
fetch.interceptors.request.use(function (config) {
  config.headers['Authorization'] = `bearer ${readCookie('pod')}`
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default fetch;