<template>
  <div id="chartdiv"></div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export default {
  name: 'StackedColumns',
  props: [ 'data', 'rawData' ],
  mounted() {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("chartdiv");
    root.numberFormatter.set("numberFormat", "#.#a");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    var data = this.data;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "type",
      renderer: am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.data.setAll(data);

    var yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.labels.template.set('visible', false)

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: yRenderer,
      calculateTotals: true,
    }));

    // First column
    this.makeSeries(chart, xAxis, yAxis, root, data, "KAP-KL - Premie", "kap-kl-pr", false, '#FCC521', false, '', 'KAP-KL');
    this.makeSeries(chart, xAxis, yAxis, root, data, "KAP-KL - Förmån", "kap-kl-fr", true, '#148377', true, '#000', 'KAP-KL');

    // Second column
    this.makeSeries(chart, xAxis, yAxis, root, data, "AKAP-KR - Premie", "akap-kr-pr", false, '#FCC521', true, '#000', 'AKAP-KR');

    chart.appear(1000, 100);
  },
  methods: {
      makeSeries(chart, xAxis, yAxis, root, data, name, fieldName, stacked, color, showTotal, totalColor, totalLabel) {
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          stacked: stacked,
          name: name,
          totalLabel,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          clustered: false,
          categoryXField: "type",
        }));

        series.columns.template.setAll({
          tooltipText: "{name}: {valueY} sek/mån",
          width: am5.percent(90),
          tooltipY: am5.percent(10),
          fill: color,
          stroke: color,
        });
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();
        if (showTotal) {
          series.bullets.push(function(target, series, dataItem) {
            var index = chart.series.indexOf(series);
            var dataItemIndex = series.dataItems.indexOf(dataItem);
            var sum = 0;
            chart.series.eachReverse(function(series, seriesIndex) {
              if (seriesIndex <= index) {
                var seriesDataItem = series.dataItems[dataItemIndex];
                sum += seriesDataItem.get("valueY");
                if (!series.get("stacked")) {
                  index = -1;
                }
              }
            });
            return am5.Bullet.new(root, {
              locationY: 1,
                  sprite: am5.Label.new(root, {
                    text: "{totalLabel}\n" + root.numberFormatter.format(sum),
                    fill: totalColor,
                    centerY: am5.percent(0),
                    centerX: am5.percent(50),
                    fontWeight: 700,
                    populateText: true,
                  })
            });
          });
      }
    }
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
</script>

<style lang="scss" scoped>
#chartdiv {
  width: 100%;
  height: 21.25rem;
}
</style>