import Vue from 'vue';
import fetch from '../services/fetch';

const state = {
  user: {
    status: null,
    personalNumber: '',
    name: '',
    role: '',
    firstName: '',
    lastName: '',
    age: null,
    access: {},
    contact: {},
    fullmakt: {},
    kapkl: {
      sallary: 0
    },
  }
};

const getters = {
  user: (state) => state.user
};

const helper = {
  setAge(personalNumber) {
    // Figure out age based on personal number [personnummer]
    let pnoFirstPart = personalNumber.substr(0, 8);
    let today = new Date();
    let currentDate = today.getDate();
    let currentMonth = today.getMonth() + 1;
    currentDate < 10 ? currentDate = '0' + currentDate : currentDate;
    currentMonth < 10 ? currentMonth = '0' + currentMonth : currentMonth;
    let date = today.getFullYear() + '' + currentMonth + '' + currentDate;
    let currentAge = date - pnoFirstPart;
    return currentAge.toString().substr(0, 2)
  },
  setfirstName(name) {
    let firstName = name.split(" ")[0];
    return firstName.charAt(0) + firstName.slice(1).toLowerCase();
  }
}

const actions = {
  async setUser({ commit, dispatch }, user) {
    commit('setUser', user)
  },
  async getKapkl({ commit }, sallary) {
    return fetch.post('/kapkl', { monthlySalary: sallary }).then(res => {
      if (res.data) {
        commit('updateKapkl', { sallary, ...res.data });
      }
    }).catch(err => {
      throw err
    })
  },
  async updateUser({ commit, state, dispatch }, setting) {
    let obj = { 'access': {} }

    obj.access = { ...state.user.access }

    Object.keys(state.user.access[setting.field]).map((key, i) => {
      i
        ? obj.access[setting.field][key] = setting.secondValue
        : obj.access[setting.field][key] = setting.firstValue
    });

    let newAccess = {
      ...state.user.access,
      ...obj.access
    }

    fetch.put('/user', { update_data: obj }).then(res => {
      if (res.data.success) {
        commit('updateUser', newAccess)
      }
    }).catch(err => {
      throw err
    })
  },
  async updateContact({ commit, state, dispatch }, contact) {
    commit('setUserStatus', 'uploading')

    return fetch.put('/user', { update_data: contact }).then(res => {
      if (res.data.success) {
        commit('updateContact', contact)

        commit('setUserStatus', 'loaded')
      }
    }).catch(err => {
      throw err;
    })
  },
  async updateCollectiveAgreements({ commit, state, dispatch }, agreement) {
    let obj = { 'access': {} }

    obj.access = { ...state.user.access }

    obj.access.collectiveAgreement = agreement

    let newAccess = {
      ...state.user.access,
      ...obj.access
    }

    fetch.put('/user', { update_data: obj }).then(res => {
      if (res.data.success) {
        commit('updateCollectiveAgreements', newAccess)
      }
    }).catch(err => {
      throw err
    })
  },
  async updateFullmakt({ commit, state, dispatch }, poaid) {
    let fullmakt = {
      fullmakt: {
        status: 'Unsigned',
        poaid: poaid
      }
    }

    return fetch.put('/user', { update_data: fullmakt })
      .then(() => {
        commit('changefullmakt', fullmakt)

        return new Promise(function (resolve, reject) {
          let i = 0

          const interval = setInterval(() => {
            i++

            fetch.post('/fullmakt', { action: 'get', 'POAId': poaid }).then(res => {
              if (res.data.POAGetResponse.Status == 'Signed') {
                clearInterval(interval)

                resolve(true)
              } else if (i > 24) {
                clearInterval(interval)

                reject(false)
              }
            })
          }, 10000)
        })
      })
      .then(async res => {
        if (res) {
          fullmakt = {
            fullmakt: {
              status: 'Signed',
              poaid: poaid
            }
          }

          return fetch.put('/user', { update_data: fullmakt }).then((res) => {
            if (res.data.success) {
              commit('changefullmakt', fullmakt)
              commit('setUserStatus', 'loaded')
              return true
            }
          })
        }
      })
      .catch(err => {
        throw err
      })
  },
  async createFullmakt({ commit, state, dispatch }, date) {
    commit('setUserStatus', 'uploading')

    return fetch.post('/fullmakt', { action: 'create', validTo: date })

      .then(res => {
        if (res.data.success) {
          return dispatch('updateFullmakt', res.data.POAIdentity.POAId)
        }
      })
      .catch(err => {
        commit('setUserStatus', 'loaded')
        return false
      })
  },
  async searchFullmakt({ commit, state, dispatch }) {
    return fetch.post('/fullmakt', { action: 'search' })
      .then(res => {
        if (res.data.success && res.data.POASearchResponse.Data !== '') {
          const fullmakt = {
            fullmakt: {
              poaid: res.data.POASearchResponse.Data.POAListData.POAId,
              status: res.data.POASearchResponse.Data.POAListData.Status
            }
          }

          commit('changefullmakt', fullmakt)
          return dispatch('updateFullmakt', res.data.POASearchResponse.Data.POAListData.POAId)
        }
      })
  }
};

const mutations = {
  setUserStatus: (state, status) => (state.user.status = status),
  setUser: (state, user) => (state.user = {
    ...user,
    age: helper.setAge(user.personalNumber),
    name: user.name.toLowerCase(),
    firstName: helper.setfirstName(user.name),
    status: 'loaded'
  }),
  updateUser: (state, newAccess) => (
    state.user.access = newAccess
  ),
  updateContact: (state, data) => (
    state.user.contact = data.contact
  ),
  updateKapkl: (state, data) => (
    state.user.kapkl = data
  ),
  changefullmakt: (state, data) => (
    state.user.fullmakt = data.fullmakt
  ),
  updateCollectiveAgreements: (state, agreement) => (
    state.user.access = agreement
  )
};

export default {
  state,
  getters,
  actions,
  mutations
}