<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  created() {
    let vh = window.innerHeight * 0.01;
    const html = document.querySelector('html');
    html.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      html.style.setProperty('--vh', `${vh}px`);
    });
  },
  watch:{
    $route (to, from){
      const html = document.querySelector('html');

      if(to.name === 'gdpr' || to.hash === '#counted') {
        html.style.setProperty('height', `auto`);
      } else {
        let vh = window.innerHeight * 0.01;
        html.style.setProperty('--vh', `${vh}px`);
        html.style.removeProperty('height');
      }
    }
  },
}
</script>


<style lang="scss">
html {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  height: 100%;
}

::placeholder {
  opacity: 0.5;
}
</style>