<template>
  <div class="gather-info">
    <Header/>

    <section class="gather-info__section">
      <section class="gather-info__text">
        <h1>Dina uppgifter</h1>
        <p>
        För att kunna räkna på vad detta innebär för dig, behöver du ange nuvarande månadslön nedan.
        </p>
      </section>

      <div>
        <div class="gather-info__input">
          <input
            type="number"
            v-model="monthlySallary"
            placeholder="Månadslön innan skatt"
          >

          <p>kr/mån</p>
        </div>

        <button
          class="btn btn--full-width btn--align-bottom"
          @click="this.sendData"
        >
          Nästa
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Header from '../Header.vue'

export default {
  name: 'CurrentAnalysisInfo',
  components: { Header },
  data() {
    return {
      monthlySallary: '',
      months: ''
    }
  },
  methods: {
    ...mapActions(['getKapkl', 'cancelSignIn']),
    sendData() {
      this.getKapkl(this.monthlySallary).then(() => {
        this.$emit('data', {
          monthlySallary: this.monthlySallary,
          months: this.months
        })
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.gather-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img:first-of-type {
      width: 100px;
    }
  }

  &__text {
    margin-top: 4rem;
  }

  &__title {
    text-align: center;
  }

  &__input-container {
      width: 100%;
  }

  &__input {
    position: relative;

    p {
      position: absolute;
      top: .1rem;
      right: 1rem;
      font-weight: bold;

      @include breakpoints(medium) {
        top: .4rem;
      }
    }
  }

  &__section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoints(medium) {
    &__text {
      margin-bottom: 2rem;
    }

    &__section {
      width: 70%;
      margin: 0 auto;
      height: 100%;
      justify-content: center;
    }
  }
}
</style>