<template>
  <main class="timeline">
    <Header/>

    <section class="timeline__container">
      <h1 class="timeline__title">
        Så här fungerar det
      </h1>

      <section class="timeline__content">
        <ul>
          <li v-for="(info, index) in timeline_data" v-bind:key="index">
            <span></span>
            <div>{{ info.school }}</div>
            <div>{{ info.address }}</div>
            <div>{{ info.course }}</div>
            <div class="year">
              <span>{{ info.yearEnd }}</span>
              <span>{{ info.yearStart }}</span>
            </div>
          </li>
        </ul>
      </section>
    </section>

    <router-link
      class="btn btn--align-bottom btn--full-width"
      to="/"
    >
      Tillbaka
    </router-link>
  </main>
</template>

<script>
import Header from '../components/Header.vue'

export default {
  name: 'Timeline',
  components: { Header },
  data() {
    return {
      timeline_data: [
        {
          "school": "Du blir informerad",
          "address": "Avtalet träder i kraft.",
          "course": "Automatisk övergång för anställda i KAP-KL som inte har ett pensionsunderlag överstigande 7,5 IBB.",
          "yearStart": "",
          "yearEnd": "1 jan"
        },
        {
          "school": "Valperiod öppnar",
          "address": "Anställda med ett pensionsunderlag överstigande 7,5 IBB skall göra ett aktivt val om att kvarstå i KAP-KL eller byta till AKAP-KR.",
          "course": "",
          "yearStart": "31 maj",
          "yearEnd": "1 april"
        },
        {
          "school": "Arbetsgivare inrapporterar",
          "address": "Sista datum för att rapportera in som arbetsgivaren en arbetstagare på nytt pensionsavtal för anställda som valt att övergå till AKAP-KR.",
          "course": "",
          "yearStart": "30 juni",
          "yearEnd": ""
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin clearfix {
  content: '';
  position: absolute;
  top: 0; left: 0;
}

@mixin set-border($thickness, $color, $radius) {
  border: $thickness solid $color;
  border-radius: $radius;
}

.timeline {
  height: auto;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  &__content {
    ul {
      position: relative;

      &::before {
        @include clearfix();
        height: 100%;
      }
    }

    li {
      position: relative;
      margin: 60px 35px;
      list-style: none;
      line-height: 25px;

      & > span {
        @include clearfix();
        @include set-border(2px, $main-color, none);
        left: -25px;
        height: 110%;

        &::before, &::after {
          @include clearfix();
          @include set-border(3px, $main-color, 50%);
          width: 8px;
          height: 8px;
          left: -7px;
        }

        &::before {
          top: -15px;
        }

        &::after {
          top: 100%;
        }
      }

      div {
        &:nth-child(2) {
          font-size: 1.2em;
          margin-bottom: 7px;
          font-weight: bold;
        }

        &:nth-child(3), &:nth-child(4) {
          font-size: 1em;
          color: darken($main-color, 25%);
        }
      }

      .year span {
        position: absolute;
        font-size: 0.9em;
        line-height: 16px;
        left: -90px;
        width: 50px;
        text-align: right;

        &:first-child {
          top: -15px;
        }

        &:last-child {
          top: calc(100% + 5px);
        }
      }
    }
  }

  @include breakpoints(medium) {
    width: 60%;
    margin: 0 auto;
    padding: 3rem;

    &__container, .btn {
      max-width: 70%;
      margin: 0 auto;
    }
  }
}
</style>