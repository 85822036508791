import Vue from 'vue'
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import CurrentAnalysis from '../views/CurrentAnalysis.vue';
import GDPR from '../views/GDPR.vue';
import Timeline from '../views/Timeline.vue';
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/current-analysis',
    name: 'current-analysis',
    component: CurrentAnalysis,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: GDPR,
    meta: {
      guest: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

router.beforeEach((to, from, next) => {
  let userStatus = store.getters.user.status;
  switch (userStatus) {
    case 'loaded':
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (to.matched.some(record => record.meta.requiresCompilation)) {
          next({ path: '/', replace: true });
        }
        else {
          next();
        }
      }
      else if (to.matched.some(record => record.meta.guest)) {
        next({ path: '/', replace: true });
      }
      else {
        next();
      }
      break;
    case null:
      store.dispatch('check_loggedIn')
        .then((res) => {
          if (res) {
            if (to.name === 'login') {
              next({ path: '/', replace: true });
            } else {
              next({ path: to.fullPath, replace: true });
            }
          } else {
            if (to.matched.some(record => record.meta.requiresAuth)) {
              next({ path: '/login', replace: true });
            } else {
              next();
            }
          }
        })
        .catch((err) => {
          if (to.matched.some(record => record.meta.guest)) {
            next();
          } else {
            next({ path: '/login', replace: true });
          }
        })
      break
    case 'unauthorized':
      if (to.matched.some(record => record.meta.guest)) {
        next();
      } else {
        next({ path: '/login', replace: true });
      }
      break
    default:
      next();
      break;
  }

})

export default router;