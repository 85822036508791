import { eraseCookie } from './cookie';

let inactivityTime = function() {
  let time;
  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;

  function logout() {
    eraseCookie('pod')

    location.reload()
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, 60 * 60 * 1000) // 1 h
  }
};

window.onload = function() {
  inactivityTime();
}
