<template>
    <main class="gdpr">
      <Header/>

      <section class="gdpr__container">
        <h1 class="gdpr__title">
          Vilka personuppgifter vi samlar in och varför vi samlar in dem
        </h1>

        <div class="text-box">
          <div class="text-box__text">
            <h2>Dataskyddspolicy</h2>
            <p>
              Pension on Demand värnar skyddet av dina enskilda rättigheter och dina personuppgifter. Den här dataskyddspolicyn beskriver hur vi samlar in, använder, lagrar och delar personuppgifter.
              <br/><br/>
              <strong>Dataskyddspolicyn innehåller följande delar:</strong>
            </p>

            <h2>Vilka personuppgifter vi samlar in</h2>
            <p>
              Personuppgifter samlas normalt in direkt från dig eller genereras i samband med att du använder våra tjänster och produkter. Ibland krävs ytterligare information för att hålla uppgifterna aktuella eller kontrollera att uppgifterna vi samlat in är korrekta.
            </p>

            <h3>De personuppgifter som vi samlar in kan delas in i följande kategorier:</h3>
            <ul>
              <li>Identifikationsuppgifter: Personnummer och namn. Vi är skyldiga att samla in dokumentation av sådana uppgifter, till exempel i form av kopia av ditt pass, ID eller körkort.</li>
              <li>Kontaktuppgifter: Telefonnummer och adresser, inklusive postadress – för utländska adresser även hemland.</li>
              <li>Ekonomisk information: Typ av avtal, transaktionsinformation.</li>
              <li>Uppgifter som krävs enligt lag: uppgift om skattehemvist, uppgifter som krävs för grundläggande kundkännedom (KYC) och bekämpning av penningtvätt (AML).</li>
            </ul>

            <h3>Personuppgifter som vi kan komma att samla in direkt från dig:</h3>
            <ul>
              <li>Vi samlar in uppgifter du lämnar direkt till oss. Som ny kund ber vi dig till exempel om personuppgifter såsom namn, personnummer, e-postadress och telefonnummer samt uppgifter om inkomst och skulder, för att kunna erbjuda dig den produkt eller tjänst du är intresserad av och som passar dig.</li>
              <li>Vi samlar också in information som du meddelar oss, till exempel genom återkoppling eller annan interaktion i våra digitala kanaler.</li>
            </ul>

            <h3>Personuppgifter som vi kan komma att samla in från tredje part:</h3>
            <ul>
              <li>Publika eller andra externt tillgängliga källor: register som förs av myndigheter (till exempel folkbokföringsregister, skattemyndighetens register, bolagsregister och register hos brottsbekämpande myndigheter), sanktionslistor (hos internationella organisationer såsom EU), register som förs av kreditupplysningsföretag och andra kommersiella informationsleverantörer av uppgifter om exempelvis verkliga huvudmän och personer i politiskt utsatt ställning (PEP).</li>
              <li>Från andra företag som vi samarbetar med.</li>
            </ul>

            <h2>Hur vi kan komma att använda dina personuppgifter och på vilken laglig grund</h2>
            <p>
              Vi använder dina personuppgifter för att uppfylla rättsliga förpliktelser och avtalsförpliktelser, samt för att ge dig erbjudanden, rådgivning och annan service.
            </p>

            <h3>Teckna och administrera service- och produktavtal (fullgörande av avtal)</h3>
            <p>
              Huvudsyftet med vår behandling av personuppgifter är att samla in, kontrollera och behandla personuppgifter inför och vid tecknandet av avtal med dig, liksom att dokumentera, administrera och utföra det som krävs för att fullgöra avtal.
              <br/><br/>
              Exempel på behandling för att fullgöra ett avtal:
            </p>
            <ul>
              <li>Behandling för att öppna depå</li>
              <li>Kundservice under avtalsperioden</li>
              <li>Fastställa, göra gällande eller försvara rättsliga anspråk</li>
            </ul>

            <h3>Uppfylla krav och rättsliga förpliktelser</h3>
            <p>
              Förutom för fullgörande av avtal behandlar vi också personuppgifter för att uppfylla de förpliktelser vi har enligt lag, andra författningar eller myndighetsbeslut.
              <br/><br/>
              Exempel på behandling till följd av rättsliga förpliktelser:
            </p>
            <ul>
              <li>Kundkännedom: Åtgärder för att förhindra, upptäcka och utreda penningtvätt, terroristfinansiering och bedrägeri</li>
              <li>Bokföringslagstiftning: Rapportering till skattemyndigheter, polismyndigheter, kronofogdemyndigheter och tillsynsmyndigheter</li>
              <li>Hantering av risker avseende exempelvis kapitaltäckning och försäkringar</li>
              <li>Övriga förpliktelser gällande produkter och tjänster som omfattas av särskild lagstiftning, till exempel värdepapper, fonder och försäkringar</li>
            </ul>

            <h3>Marknadsföring, produkt- och kundanalys (berättigat intresse)</h3>
            <p>
              Personuppgifter behandlas också i samband med marknadsföring och produkt- och kundanalyser. Syftet med den här behandlingen är dels marknadsföring, dels process-, affärs- och systemutveckling, inklusive testning. Vi gör detta för att förbättra vårt produktutbud och optimera våra erbjudanden.
            </p>

            <h3>Samtycke</h3>
            <p>
              Slutligen kan det finnas tillfällen när vi ber om ditt samtycke till att behandla personuppgifter. Det kan till exempel vara vid viss behandling av särskilda kategorier av personuppgifter. Samtycket kommer att innehålla information om den särskilda behandlingen. Om du har lämnat samtycke till behandling av dina personuppgifter kan du alltid återkalla samtycket.
            </p>

            <h2>Vem vi kan komma att lämna ut dina personuppgifter till</h2>
            <p>
              Vi kan dela dina personuppgifter med andra, till exempel myndigheter, leverantörer och affärspartners. Innan vi delar sådana uppgifter säkerställer vi alltid att vi följer de sekretessförpliktelser som gäller för finanssektorn.
              <br/><br/>
              När vi utför tjänster och uppfyller avtal måste vi ibland lämna ut uppgifter om dig. Om du till exempel har bett oss att göra en överföring av pengar eller värdepapper måste vi lämna ut vissa uppgifter om dig för att kunna utföra den överföringen.
            </p>

            <h3>Tredjepart</h3>
            <p>
              När vi utför våra tjänster, till exempel en orderläggning, lämnar vi ut uppgifter om dig som behövs för att identifiera dig och utföra uppdraget eller uppfylla avtalet tillsammans med de företag som vi samarbetar med. Det kan till exempel röra sig om utlämnande vid användning av säkra identifieringslösningar i det berörda landet och vid utbyte av information mellan finansiella motparter såsom banker, betalningsmottagare och clearinginstitut.
              <br/><br/>
              Vi lämnar också ut personuppgifter till myndigheter i den omfattning vi har en rättslig skyldighet att göra det. Här avses exempelvis skattemyndigheter och tillsynsmyndigheter i berörda länder.
              <br/><br/>
              Dessutom lämnar vi ut uppgifter, med ditt medgivande eller om det är tillåtet enligt lag eller annan författning till andra företag som vi samarbetar med. För att kunna erbjuda våra tjänster kan vi också lämna ut personuppgifter till exempelvis försäkringsbolag.
              <br/><br/>
              Vi har tecknat avtal med utvalda leverantörer, och dessa avtal innefattar bestämmelser om behandling av personuppgifter för vår räkning. Leverantörerna finns till exempel inom utveckling, underhåll, drift och support av IT-system.
            </p>

            <h3>Överföring av personuppgifter till tredje land</h3>
            <p>I vissa speciella fall kan vi även överföra personuppgifter till organisationer i s.k. tredjeländer (länder utanför Europeiska ekonomiska samarbetsområdet, EES). Sådana överföringar kan göras om något av följande villkor uppfylls:</p>
            <ul>
              <li>EU-kommissionen har beslutat att det finns en adekvat skyddsnivå i det aktuella landet, eller andra lämpliga skyddsåtgärder har vidtagits.</li>
              <li>Det rör sig om ett undantag i en särskild situation, till exempel för att fullgöra ett avtal med dig eller om du ger ditt samtycke till den specifika överföringen.</li>
            </ul>

            <h2>Hur vi skyddar dina personuppgifter</h2>
            <p>
              Att förvara dina personuppgifter på ett tryggt och säkert sätt är en central del i hur vi gör affärer. Vi har vidtagit lämpliga tekniska, organisatoriska och administrativa säkerhetsåtgärder för att skydda de uppgifter vi har mot förlust, missbruk och obehörig åtkomst, röjande, ändring och förstöring.
            </p>

            <h2>Dina rättigheter i fråga om dataskydd</h2>
            <p>Som registrerad person har du rättigheter i fråga om de personuppgifter vi har om dig. Du har följande rättigheter:</p>

            <h3>Begära åtkomst till dina personuppgifter</h3>
            <p>
              Du har rätt att få åtkomst till de personuppgifter som vi har om dig. I många fall finns dessa uppgifter redan i de onlinetjänster vi erbjuder dig. Din rätt till åtkomst kan emellertid begränsas av lagstiftning, skydd av annan persons privatliv samt hänsyn till skydd av Bolagets affärskoncept och affärsrutiner. När det gäller Bolagets know-how, affärshemligheter liksom interna bedömningar och underlag kan din rätt till åtkomst vara begränsad.
            </p>

            <h3>Begära rättelse av felaktiga eller ofullständiga personuppgifter</h3>
            <p>
              Om personuppgifterna är felaktiga eller ofullständiga har du rätt att begära rättelse av uppgifterna, med de begränsningar som stipuleras i lag eller annan författning.
            </p>

            <h3>Begära radering</h3>
            <p>
              Du har rätt att begära att dina personuppgifter raderas om:
            </p>
            <ul>
              <li>Du återkallar ditt samtycke till behandlingen och det inte finns något annat rättslig grund för behandlingen</li>
              <li>Du invänder mot behandlingen och det inte finns något berättigat skäl för fortsatt behandling</li>
              <li>Du invänder mot behandling för direkt marknadsföring</li>
              <li>Behandlingen är olaglig eller</li>
              <li>Behandlingen av personuppgifter avser underårig, om uppgifterna samlades in i samband med erbjudande av informationssamhällets tjänster.</li>
            </ul>
            <p>
              Till följd av lagstiftningen för finanssektorn är vi i många fall skyldiga att behålla personuppgifter om dig under den tid som du är kund hos oss, och även därefter för att till exempel fullgöra en laglig skyldighet eller hantera rättsliga anspråk.
            </p>

            <h3>Begränsning av behandling av personuppgifter</h3>
            <p>
              Om du motsätter dig riktigheten i de uppgifter som vi har registrerade om dig eller den lagligheten för behandling av uppgifterna, eller om du utnyttjat din rätt till invändningar mot behandlingen av uppgifterna, kan du begära att vi begränsar behandlingen av dessa uppgifter till endast lagring. Behandlingen begränsas då till att endast avse lagring tills rättelsen av uppgifterna har genomförts, eller tills det går att fastställa att våra berättigade intressen har företräde framför dina intressen.
              <br/><br/>
              Om du inte har rätt till radering av de uppgifter som vi har registrerade om dig kan du istället begära att behandlingen av dessa uppgifter begränsas till endast lagring. Om behandlingen av de uppgifter som vi har registrerade om dig endast behövs för att hävda ett rättsligt anspråk, kan du också kräva att all annan behandling av dessa uppgifter begränsas till lagring. Vi får behandla dina uppgifter i andra syften om det är nödvändigt för att hävda ett rättsligt anspråk eller om du har lämnat ditt samtycke.
            </p>

            <h3>Dataportabilitet</h3>
            <p>
              Du har rätt att få ut personuppgifter som du har lämnat till oss, i ett maskinläsbart format. Detta gäller endast personuppgifter som behandlas automatiserat med stöd av samtycke eller för att fullgöra ett avtal. Om det är säkert och tekniskt möjligt kan vi också föra över personuppgifterna till en annan personuppgiftsansvarig.
              <br/><br/>
              Din begäran om att utöva dina rättigheter ovan bedöms från fall till fall utifrån rådande omständigheter. Observera att vi också kan behålla och använda dina uppgifter om det behövs för att uppfylla rättsliga förpliktelser, hävda ett rättsligt anspråk eller genomdriva våra avtal.
            </p>

            <h2>Cookies (kakor)</h2>
            <p>
              Vi samlar in, behandlar och analyserar uppgifter om användningen av våra webbsidor. Trafikdata är uppgifter kopplade till besökare på webbsidan och uppgifterna som hanteras i kommunikationsfält för att skicka, distribuera eller göra meddelanden tillgängliga.
              <br/><br/>
              Vi använder cookies och liknande teknik för att leverera produkter och tjänster till dig, erbjuda en säker onlinemiljö, hantera vår marknadsföring och ge dig en bättre onlineupplevelse, följa upp webbplatsens prestanda samt göra innehållet på våra webbsidor mer relevant för dig. Informationen används inte för att identifiera enskilda besökare.
              <br/><br/>
              Du kan ange i webbläsarens inställningar om du vill tillåta eller inte tillåta cookies. Om du väljer att inte tillåta cookies kan du ändå använda våra webbsidor och en del tjänster, men användningen av vissa funktioner och delar av vår webbplats och tjänster kan vara kraftigt begränsad.
            </p>

            <h2>Hur länge vi behandlar dina personuppgifter</h2>
            <p>
              Vi sparar dina uppgifter så länge det behövs för de ändamål de samlades in och behandlades för eller så länge som krävs enligt lagar och andra författningar.
              <br/><br/>
              Detta innebär att vi sparar dina uppgifter så länge som det är nödvändigt för att fullgöra ett avtal och så länge det krävs enligt gällande minimikrav på lagringstider i lagar och andra författningar. I de fall där vi behåller dina uppgifter i annat syfte än för att fullgöra ett avtal, till exempel för krav gällande bekämpning av penningtvätt, bokföring och krav enligt kapitaltäckningsregler, behåller vi endast uppgifterna om det är nödvändigt och/eller krävs för ändamålet i fråga enligt vad som framgår av respektive lag eller annan författning.
              <br/><br/>
              Kraven på lagringstider ser olika ut beroende på nationell lagstiftning.
              <br/><br/>
              Några exempel på situationer som kräver datalagring:
            </p>
            <ul>
              <li>Förhindra, upptäcka och utreda penningtvätt, terroristfinansiering och bedrägeri: minst fem år efter avslutad affärsförbindelse eller genomförd enskild transaktion</li>
              <li>Redovisningslagstiftning: upp till tio år</li>
              <li>Specifika bestämmelser för tjänster och produkter gällande värdepapper och försäkringar: upp till sju år</li>
              <li>Uppgifter om fullgörande av ett avtal: upp till tio år efter avslutad kundrelation i syfte att utgöra bevis vid eventuella krav (regler kring preskription)</li>
            </ul>
            <p>
              Ovan är endast exempel, minimikraven för lagringstid kan se olika ut från land till land.
            </p>

            <h2>Kontakta oss</h2>
            <p>
              Pension on Demand följer svensk dataskyddslagstiftning och är personuppgiftsansvarigt för behandlingen av dina personuppgifter enligt ovan. Om du har frågor eller synpunkter gällande vår dataskyddspolicy är du välkommen att vända dig till vår dataskyddsansvarige.
              <br/><br/>
              <strong>Pension on Demand</strong>
              <br/>
              Att: Dataskyddsansvarig
              <br/>
              Alfagatan 7, 431 49  Mölndal
              <br/>
              E-post: gdpr@pensionondemand.se
              <br/>
              Tel. 031 718 18 00
            </p>
          </div>
        </div>
      </section>

      <router-link
        class="btn btn--full-width btn--align-bottom"
        to="/login"
      >
        Tillbaka
      </router-link>
    </main>
</template>

<script>
import Header from '../components/Header.vue'

export default {
  name: 'GDPR',
  components: { Header }
}
</script>

<style lang="scss" scoped>
.gdpr {
  height: 100%;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  &__title {
    margin-top: 2rem;
  }

  @include breakpoints(medium) {
    width: 60%;
    margin: 0 auto;

    &__container, .btn {
      max-width: 70%;
      margin: 0 auto;
    }
  }
}
</style>