<template>
  <div class="simple-outcome">
    <Header/>

    <section class="simple-outcome__section">
      <h1>Nulägesanalys</h1>

      <div class="simple-outcome__outcome-data-wrapper">
        <div class="simple-outcome__outcome-data">
          <h2>
            Stanna kvar
          </h2>
          <p>{{totalBefore}}/mån</p>
        </div>

        <div class="simple-outcome__outcome-data">
          <h2>
            Vid byte
          </h2>
          <p>{{totalAfter}}/mån</p>
        </div>
      </div>
    </section>


    <section class="simple-outcome__section">
      <a class="link" href="#counted" @click="next()">Så har vi räknat</a>

      <a
        class="btn btn--full-width btn--align-bottom"
        href="https://www.valcentralen.se/Portal.aspx"
        target="_blank"
      >
        Gör ditt val
      </a>

      <button
        class="btn btn--full-width btn--align-bottom btn--outlined"
        @click="back()"
      >
        Tillbaka
      </button>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Header from '../Header.vue'

export default {
  name: 'CurrentAnalysisInfo',
  props: [ 'data', 'roleAnalysis', 'showGraph' ],
  components: { Header },
  data() {
    return {
      monthlySallary: '',
      months: ''
    }
  },
  methods: {
    next() {
        this.showGraph();
    },
    back() {
      this.$router.replace({ name: 'home' });
    }
  },
  computed: {
    ...mapGetters(['auth', 'user']),
    totalBefore() {
        return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK', maximumSignificantDigits: 3 }).format(this.user.kapkl.kapkl.totalAfterPension)
    },
    totalAfter() {
        return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK', maximumSignificantDigits: 3 }).format(this.user.kapkl.akapkr.totalAfterPension)
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-outcome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img:first-of-type {
      width: 100px;
    }
  }

  &__outcome-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  &__outcome-data {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.25);
    padding: 1rem;
    border-radius: 30px;

    p, h2 {
      margin: 0;
    }
  }

  &__section {
    text-align: center;
  }

  section:last-of-type {
    margin-top: 1rem;
  }


  @include breakpoints(medium) {
    &__section {
      width: 70%;
      margin: 0 auto;
    }

    &__outcome-data-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }

    &__outcome-data {
      width: 45%;
    }
  }
}
</style>